//Uusin postaus aina ensimmäiseksi!
export const facebookPosts = [
  "https://www.facebook.com/photo/?fbid=414568098299502&set=a.125268610562787",
  "https://www.facebook.com/photo?fbid=391428223946823&set=a.125268610562787",
  "https://www.facebook.com/photo/?fbid=370679592688353&set=pcb.370679646021681",
  "https://www.facebook.com/photo/?fbid=359579443798368&set=pcb.359579687131677",
  "https://www.facebook.com/photo/?fbid=300720049684308&set=a.125268610562787",
  "https://www.facebook.com/photo/?fbid=286179204471726&set=a.125268610562787",
  "https://www.facebook.com/photo/?fbid=205991242490523&set=a.125268610562787",
];
export const instagramPosts = [
  "https://www.instagram.com/p/C71yRxkIrvk/",
  "https://www.instagram.com/p/C71yRxkIrvk/",
  "https://www.instagram.com/p/C7UKXlgo2la/",
  "https://www.instagram.com/p/C4dja2Ro6Jc/",
  "https://www.instagram.com/p/C25JraOIWwS/",
  "https://www.instagram.com/p/C1g3jAyoejo/",
  "https://www.instagram.com/p/CwcC7dkIHjy/",
];
