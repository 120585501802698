import photo from "./Images/members/logosmall.jpg";

export const membersList = [
  {
    photo: photo,
    name: "Simo",
    intro:
      "Hylätyillä leirintävarusteilla täytetty rikkinäinen makuupussi joka jätettiin aikoinaan jögevatreffin leirintäalueelle. Simoa muistellaan joka vuosi ja otetaan huikat.",
    id: "member1",
  },
];
